import React, { useMemo, useState } from 'react'
import useFetchImgingredient from '../../hook/useFetchImgIngredient';
import Loading from '../Loading';
import scaleIngredient from '../../function/scaleIngredient';

export default function IngredientModify({ingredient,modify,del}) {
    const img = useFetchImgingredient(ingredient.path)

    const [quantity,setQuantity] = useState(ingredient.quantity)
    const [unit,setUnit] = useState(ingredient.unit)
    const unitList = useMemo(()=>['tête(s)','tube(s)','tranche(s)','tige(s)','selon le goût','sachet(s)','sac(s)','rouleau(x)','pot(s)','pièce(s)','pincée(s)','paquet(s)','ml','mg','g','filet(s)','feuille(s)','cube(s)','cs','cm','cc','brin(s)','boîte(s)','bouteille(s)','boule(s)','botte(s)',])

    function handelunit(e){
        const u = e.target.value
        setUnit(u)
        modify({...ingredient,unit:u})
    }

    function handelQuantity(e){
        const q = parseInt(e.target.value) 
        setQuantity(q)
        modify({...ingredient,quantity:q})
    }

    return (
        <div className={ 'ingredient ingredient--rond contenair contenair--centre contenair--space-evently'} >
            {img === false? <Loading/> :<img src= {URL.createObjectURL(img)} alt="" />}
            <div className='ingredient__nom relative' >
                {ingredient.name}
                <span className='material-symbols-outlined btn ingredient__remove' onClick={()=>{del(ingredient)}}>delete</span>
            </div>
            <div className='ingredient__unit'>
                <input onChange={(e)=>{handelQuantity(e)}} type="number" value={quantity !== null ? quantity : 0} />
                <select onChange={(e)=>{handelunit(e)}} name="unit" id={ingredient.id+'unit' } value={unit !== null ?unit : ""} >
                    <option value={''}></option>
                    {unitList.map((u)=><option key={'igredient'+ingredient.idIngredient+u} value={u}>{u}</option>)}
                </select>

            </div>
        </div>
    );
}
