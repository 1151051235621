import React from 'react';
import scaleIngredient from '../../function/scaleIngredient';
import useFetchImgingredient from '../../hook/useFetchImgIngredient';
import Loading from '../Loading';

export default function IngredientAdd({ingredient,scale={active:false,numberOfPersonne:0},add}) {
    const img = useFetchImgingredient(ingredient.path)
    return (
        <div className={ 'ingredient ingredient--rond contenair contenair--centre contenair--space-evently btn'} >
            {img === false? <Loading/> :<img src= {URL.createObjectURL(img)} alt="" onClick={()=>(add(ingredient))}/>}
            <div className='ingredient__nom' >{ingredient.name}</div>
            {ingredient.quantity !== undefined ? <div className='ingredient__unit'>{scale.active ? scaleIngredient(ingredient.quantity,scale.numberOfPersonne)+' ' + ingredient.unit:ingredient.quantity +' ' + ingredient.unit}</div> :null}
        </div>
    );
}
