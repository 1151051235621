import React, { useState } from 'react'
import useFetchImgEtape from '../../hook/useFetchImgEtape'
import Loading from '../Loading'

export default function EtapeModify({etape,idRecette,classWarper,classInstruction,modify}) {

    const img = useFetchImgEtape(idRecette,etape.ordre)
    const [description,setDescription] = useState(etape.description)
    
    function handelDescription(e){
        const desc = e.target.value
        setDescription(desc)
        modify({...etape,description:desc})
    }

  return (
    <div className={'contenair '+classWarper}>
        {img === false? <Loading/> : <img src={URL.createObjectURL(img)} alt='img ulistration etape'/>}
        {/* <div className='contenair etape__info'>
            <span className='etape__info__name'>{etape.name}</span>
        </div> */}
        <div className={'etape__instruction '+classInstruction}>
           <textarea onChange={(e)=>{handelDescription(e)}} name="etape" id={etape.id} cols="40" rows="10" value={description}></textarea>
        </div>
    </div>
  )
}
