import React, { useContext, useEffect, useState } from 'react'
import { authcontext } from '../../contexte/auth/Authcontext'
import useDebounce from '../../hook/useDebounce'
import SearchBar from '../SearchBar'
import IngredientRond from '../ingredient/IngredientRond'
import IngredientAdd from './IngreedientAdd'
import IngredientModify from './IngredientModify'

export default function Addingredient({close,add}) {

    const {authFetch} = useContext(authcontext)
    const [selectedIngredient,setSelectedIngredient] = useState(null)
    const [ingredients,setIngredients] = useState([])
    const [text,setText] = useState('')
    const debouncedText = useDebounce(text,400)

    function handelText (e){
        const text = e.target.value
        setText(text)
    }

    useEffect(()=>{
        const abor = new AbortController()
        if(debouncedText !== '' && debouncedText !==undefined){
            authFetch.get(`/ingredient/searchbyname/text/${debouncedText}/page/0/limit/0`,abor.signal).then((result)=>{
                setIngredients(result)
            }).catch((err)=>{
                console.error(err)
            })
        }else{
            setIngredients([])
        }
        return ()=>{abor.abort()} 
    },[debouncedText])

    function choise(ingredient){
        setSelectedIngredient({...ingredient,unit:null,quantity:null,idIngredient:ingredient.id})
        setText('')
    }

    function setupIngredient(ingredient){
        setSelectedIngredient(ingredient)
    }

    function clearIngredient(){
        setSelectedIngredient(null)
    }

    function ajouterIngredient(ingredient){

        if(selectedIngredient === null) return
        add(ingredient)
        close()
    }


  return (
    <div className='contenair'>
        <SearchBar text={text} setText={handelText}/>
        <div className='contenair'>
            {ingredients.map((ingredient)=><IngredientAdd add={choise} key={'ingredientAdd'+ingredient.id} ingredient={ingredient}/>)}
        </div>
        {selectedIngredient !== null ? <IngredientModify ingredient={selectedIngredient} modify={setupIngredient} del={clearIngredient}/>:null}
        <div onClick={()=>{ajouterIngredient(selectedIngredient)}} className='btn btn--big btn--green'>Ajouter</div>
    </div>
  )
}
